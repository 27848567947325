<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      description: 'Image for Slider',
      fields: {
        image: { label: 'Image', type: 'media', editorLabel: true, breakpoints: { lg: { width: 1600, height: 950 }, default: { width: 400, height: 900 } } },
        short: { type: 'checkbox', label: 'Short?' },
        mobileImage: { label: 'Mobile Image', type: 'media', breakpoints: { sm: { width: 550, height: 550 } } },
        canZoom: { label: 'Can Zoom?', type: 'checkbox' },
        darkness: { label: 'Darkness Level', type: 'select', options: { none: 'None', light: 'Light', medium: 'Medium', dark: 'Dark' } },
        coverPosition: { type: 'select', label: 'Cover Position', options: { 'object-center': 'Center', 'object-bottom': 'Bottom Center', 'object-left-bottom': 'Bottom Left', 'object-right-bottom': 'Bottom Right', 'object-left': 'Left', 'object-right': 'Right', 'object-top': 'Top Center', 'object-left-top': 'Top Left', 'object-right-top': 'Top Right' } },
        coverType: { type: 'select', label: 'Cover Type', options: { 'object-cover': 'Cover', 'object-contain': 'Contain' } },
        copyAppears: { type: 'select', label: 'Set Where\/If Copy Appears', options: { notAtAll: 'Not at all', left: 'Left', right: 'Right' } },
        title: { type: 'text', label: 'Title' },
        description: { type: 'textarea', label: 'Description' },
        link: { type: 'link', label: 'Link', enabled: false },
        videoMode: { type: 'select', label: 'Video Playback Settings', options: { clickToActivate: 'Click to Activate', playAutomatically: 'Play Automatically' } },
        wistiaId: { type: 'text', label: 'Wistia ID', enabled: false },
        videoControlsLocation: { type: 'select', label: 'Video Controls Location', options: { 'top-left': 'Top Left', 'top-center': 'Top Center', 'top-right': 'Top Right', 'middle-left': 'Middle Left', 'middle-center': 'Middle Center', 'middle-right': 'Middle Right', 'bottom-left': 'Bottom Left', 'bottom-center': 'Bottom Center', 'bottom-right': 'Bottom Right' } },
        hideSlotOnMobileWhenVideo: { type: 'checkbox', label: 'Hide Slot when Video is Playing on Mobile' },
        height: { type: 'number', label: 'Min Height', enabled: false },
        maxHeight: { type: 'number', label: 'Max Height', enabled: false },
      },
      name: { label: 'Slider Image', group: 'Glue' },
      templates: [{ label: 'Slider Image', fields: { copyAppears: { value: 'notAtAll' }, coverPosition: { value: 'object-center' }, coverType: { value: 'object-cover' }, darkness: { value: 'light' }, image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Campaigns\/hp-mobile-view.PNG' }, mobileImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Campaigns\/hp-mobile-view.PNG' }, videoControlsLocation: { value: 'middle-right' }, videoMode: { value: 'playAutomatically' }, wistiaId: { value: 'rgfysyx0c0' } } }],
    })
  },
}
</script>

<template>
  <div class="bg-glueblue-700 pointer-events-none">
    <GlueSlidersSliderImage :devise="devise">
      <slot />
    </GlueSlidersSliderImage>
  </div>
</template>
