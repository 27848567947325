<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    loginError() {
      if (process.client) {
        const url = new URL(window.location.href)
        if (url.searchParams.has('error'))
          return 'Invalid Number. Please try again.'
      }
      return null
    },
  },
  mounted() {
    defineSlice({
      description: 'Login form for agents',
      fields: {
        intro: { type: 'wysiwyg', label: 'Intro' },
      },
      name: { label: 'Login', group: 'Travel Agents' },
      templates: [{
        label: 'Login',
        fields: {
          background: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/styled/3d6b4885d9d998649beaad875d4cdcd4' },
          intro: { value: '<p>If your agency is currently not registered to access or book on the Atlantis Travel Agent Website,<br>please <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.atlantisbahamas.com/travel-agent-registration"><u>click here</u></a> to fill out your information and we will get you set up.</p>' },
        },
      }],
    })
  },
}
</script>

<template>
  <div
    class="w-full flex flex-col items-center bg-teal bg-bottom bg-no-repeat bg-cover"
  >
    <img width="400" src="/imgs/ui/travel_agent_login.png" style="z-index: 200" class="my-8">
    <div class="mb-4 text-lg text-white w-full md:w-1/2 text-center" v-html="fields.intro.value" />

    <div class="bg-white mt-8 p-8">
      <form id="login-form" method="get" action="/travelagent/dashboard">
        <label class="font-sans2 w-full text-gray-600">PLEASE ENTER YOUR IATA, CLIA ARC, OR TIDS</label>
        <div v-if="loginError" class="mt-2 text-red-500">
          {{ loginError }}
        </div>
        <div class="flex mt-6">
          <fieldset class="w-3/4 mr-6">
            <input class="w-full h-full p-2 border-gray border font-sans" name="iata_num" type="text">
          </fieldset>
          <input type="submit" value="LOGIN" class="cursor-pointer block glue-btn uppercase body-11 tracking-widest py-1 px-4 bg-glueblue-600 text-white border-zinc-600">
        </div>
      </form>
    </div>
  </div>
</template>
