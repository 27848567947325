
<template>
  <div class="container mb-24 py-24">
    <div class="text-center">
      <a href="#" @click.prevent="showTerms = !showTerms">Terms &amp; Conditions</a>
      <div v-html="fields.terms.value"
            v-if="showTerms"
            class="leading-loose text-left text-xs">
      </div>
    </div>
  </div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      showTerms: false
      }
  },
  mounted () {
      defineSlice({"description":"Contains the content to a max width","fields":{"terms":{"type":"wysiwyg","label":"Terms and Conditions"}},"name":{"label":"Terms And Conditions","group":"Special Offers"},"templates":[{"label":"Terms And Conditions","fields":{"terms":{"value":"<p><br>\u2022&nbsp;&nbsp;This offer is valid for new reservation only at The Coral, The Royal, The Cove and The Reef with a minimum 2-night stay.<\/p><p><\/p><p>\u2022&nbsp;&nbsp;Up to 25% discount is off the Best Available Rates.<\/p><p>\u2022 Offer subject to VAT and Levies, Resort Fee and Mandatory Gratuities: 21% charge on nightly room rate (10% VAT, 4.4% Paradise Island Tourism and Development Association Levy (including VAT), and 6.6% Nassau Paradise Island Promotional Board Levy (including VAT)), daily Resort Fee ($59 plus a $5.90 VAT charge, totaling $64.90 at The Coral and The Royal, and $70 plus a $7 VAT charge, totaling $77 at The Cove and The Reef), and daily Mandatory Gratuities (up to $10 per person, per night, 12 and older, at The Coral and The Royal, and up to $18 per person, per night, 12 and older, at The Cove and The Reef.<\/p><p>\u2022&nbsp;&nbsp;Offer is not combined with other offers and is not valid for group bookings.<\/p><p>\u2022&nbsp;&nbsp;Offer subject to availability and blackout dates.<\/p><p>\u2022&nbsp;&nbsp;Rates are non-commissionable to travel agents.<\/p><p>\u2022&nbsp;&nbsp;Offer subject to Payment, Cancellation, and Refund Policies listed in the General Terms &amp; Conditions.<\/p><p>\u2022&nbsp;&nbsp;This offer and terms and conditions are subject to change at the discretion of the resort<\/p>"}}}]})
  },
};
</script>