<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filters: {
        categories: [],
      },
      pageRestaurants: [],
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },
  },
  mounted() {
    defineSlice({ slots: [{ name: 'default' }], description: 'Search results for dining section of Atlantisbahamas.com', fields: { showDiningTimes: { label: 'Show dining times', type: 'checkbox' }, category: { label: 'Category', instructions: '"Leave Blank For All"', type: 'select', options: { 'Specialty Dining': 'Specialty Dining', 'Casual Dining': 'Casual Dining', 'Bars & Lounges': 'Bars & Lounges', 'Quick Bites': 'Quick Bites', 'Outdoor Dining': 'Outdoor Dining', 'Patio Dining': 'Patio Dining', 'Fine Dining': 'Fine Dining', 'Contemporary Casual': 'Contemporary Casual', 'Buffet': 'Buffet', 'Celebrity Chef': 'Celebrity Chef' } } }, name: { label: 'Dining Results', group: 'Glue' }, templates: [{ label: 'Dining Results', fields: { showDiningTimes: { value: true } } }] })
    this.requestRestaurants()
  },

  methods: {
    requestRestaurants() {
      $voixFetch('/api/devise/all-restaurants')
        .then((response) => {
          this.pageRestaurants = response
        })
    },
  },
}
</script>

<template>
  <div>
    <GlueDiningDiningSearch
      v-if="pageRestaurants.data"
      :restaurants="pageRestaurants.data"
      :devise="devise"
    >
      <slot />
    </GlueDiningDiningSearch>
  </div>
</template>
